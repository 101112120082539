/*eslint-disable*/
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Input from 'components/common/Input'
import Loading from 'components/common/Loading'
import SelectDr from 'components/common/SelectDr'
import Title from 'components/common/Title'
import Table from 'components/common/table/Table'
import Modal from 'containers/modal/Modal'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCheckSquare, AiOutlineCloseSquare  } from 'react-icons/ai'
import { FaChevronDown, FaChevronRight, FaRegEdit } from 'react-icons/fa'
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdOutlineDeleteSweep } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCurrencies, getCurrenyFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/currencySlice'
import { getLocationGroupSelectListFunc } from 'redux/features/dataManagement/adminPanel/locationGroupSlice'
import { openModal } from 'redux/features/general/generalSlice'
import { permissionTypes } from 'types/permissionTypes'
import { downloadFileFromStream } from 'utils/ExcelOutput'
import CheckboxTree from 'react-checkbox-tree'
import { RiCheckboxMultipleFill } from 'react-icons/ri'
import { getApiKey, getApiKeyFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/apiKeySlice'
import DatePicker from 'react-datepicker'
import Locale from 'utils/Locale'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'


const ApiKey = () => {
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { modals } = useSelector((state) => state.general)
    console.log( useSelector((state) => state));
	const { adminPanel } = useSelector((state) => state.apiKey)

	const { modalId, modalStatus, modalToggle } = modals
	const { apiKey, apiKeyFilterParameters } = adminPanel

	const { permissions } = useSelector((state) => state.configuration)
	const { currentUser } = permissions.configurations


	console.log(apiKey, "apiKey");

	const navigate = useNavigate()

	const selectRef = useRef(null)

	const [tabModal, setTabModal] = useState(0)
	const [roleManagementPermissions, setRoleManagementPermissions] = useState([])
	const [hierarchicalTreeList, setHierarchicalTreeList] = useState([])
	const [treeChecked, setTreeChecked] = useState([])
	const [treeExpanded, setTreeExpanded] = useState([])
	const [hierarchicalList, setHierarchicalList] = useState([])

	const mapTreeNodes = (items) => {
		return items?.map((prev) => ({
			value: prev.id,
			label: prev.name,
			children: prev?.children?.length > 0 ? mapTreeNodes(prev.children) : null,
		}))
	}

	let treeNodes = mapTreeNodes(hierarchicalList)

	const [apiKeyAddingExpirationDate, setApiKeyAddingExpirationDate] = useState()
	const [apiKeyAddingAllowedIpAddresses, setApiKeyAddingAllowedIpAddresses] = useState([])
	const [apiKeyAddingCompanyId, setApiKeyAddingCompanyId] = useState()

	const [statusChecked, setStatusChecked] = useState(0)

	const [companySelectList, setCompanySelectList] = useState()
	const [userSelectList, setUserSelectList] = useState()
	const [rolesSelectList, setRolesSelectList] = useState()
	const [substituteUserSelectList, setSubstituteUserSelectList] = useState()

	//table filter area
	const [filters, setFilters] = useState({
		sortingOrderByVal: "desc",
		sortingColumnName: "",
		page: 1,
	})


	const handleRemove = (item) => {
		dispatch(
			openModal({
				open: true,
				type: { modalId: null, modalStatus: 'adminPanel-deleteModal', editableData: { ...item, deleteItem: 'apiKey' } },
			})
		)
	}
	const handleEdit = (item) => {
		request(`/api-key/${item.id}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				dispatch(
					openModal({
						open: true,
						type: {
							modalId: null,
							modalStatus: 'adminPanel-editModal',
							editableData: {
								...res.data.result,
								treeNodes: treeNodes,
								treeChecked: treeChecked,
								treeExpanded: treeExpanded,
								companySelectList: companySelectList,
								rolesSelectList: rolesSelectList,
								// substituteUserSelectList: substituteUserSelectList,
								// userSelectList: userSelectList,
								choiceManagerSelectList: {
									value: res?.data?.result?.managerId,
									label: res?.data?.result?.managerFullName,
								},
								choiceCompanySelectList: {
									value: res?.data?.result?.companyId,
									label: companySelectList?.find((compSelectList) => compSelectList?.name == res?.data?.result?.companyId)?.value,
								},
								choiceRolesSelectList: rolesSelectList
									.filter((role) => res?.data?.result?.roles?.includes(role.name))
									.map((dt) => ({ value: dt.name, label: dt.value })),
								choiceSubstituteUserList: res?.data?.result?.substitutedUsers,
								editItem: 'apiKey',
							},
						},
					})
				)
			}
		})
	}


	const COLUMNS = [
		{
			label: t('companyName'),
			renderCell: (item) => item.companyName,
			name: 'companyName',
		},
		{
			label: t('expirationDate'),
			renderCell: (item) => new Date(item.expirationDate).toLocaleDateString(i18n.language),
			name: "expirationDate"
		},
		{
			label: t('createdApiDate'),
			renderCell: (item) => new Date(item.createdDate).toLocaleDateString(i18n.language),
			name: "createdDate"
		},
		{
			label: t('modifiedDate'),
			renderCell: (item) => item.modifiedDate ? new Date(item.modifiedDate).toLocaleDateString(i18n.language) : "-",
			name: "modifiedDate"
		},
		// {
		// 	label: t('status'),
		// 	renderCell: (item) => item.status == 1 ? t('active') : t('passive'),
		// 	name: "status"
		// },
		// {
		// 	label: t('manager'),
		// 	renderCell: (item) =>
		// 		item.isManager ? <AiOutlineCheckSquare  color='green' size={27}/> : <AiOutlineCloseSquare  size={27} color='#c1312f'/>,
		// 	name: 'isManager',
		// },

		// {
		// 	label: t('roles'),
		// 	renderCell: (item) => item.roleNames.map((role, i) => (i == item.roleNames.length - 1 ? role : `${role}, `)),
		// 	name: '', // burayı incele ...
		// },

		{
			label: '',
			renderCell: (item) => (
				<div className='lastCellFlex'>
					<div className={`cabin-semibold ${item.status == 1 ? 'tableActiveStatus' : 'tablePassiveStatus'}`}>
						{item.status == 1 ? t('active') : t('passive')}
					</div>
					{currentUser?.permissions[permissionTypes.ApiKeysUpsert] && (
						<div className='editBtn' onClick={(e) => e.stopPropagation()}>
							<FaRegEdit size={15} onClick={() => handleEdit(item)} />
							<div onClick={() => handleEdit(item)} className=''>
							{t('edit')}
							</div>
						</div>
					)}
					{currentUser?.permissions[permissionTypes.ApiKeysDelete] && (
						<div className='deleteBtn' onClick={(e) => e.stopPropagation()}>
							<MdOutlineDeleteSweep size={18} onClick={() => handleRemove(item)} />
							<div onClick={() => handleRemove(item)} className=''>
							{t('delete')}
							</div>
						</div>
					)}
				</div>
			),
			name: '',
		},
	]

	useEffect(() => {
		request(`/company/select-list-hierarchical`, {
			method: 'GET',
		}).then((res) => {
			setHierarchicalList(res.data.result)
		})
	},[])

	useEffect(() => {
        setFilters(prev => ({...prev, page: 1}))
	}, [filters.sortingColumnName, filters.sortingOrderByVal])

	useEffect(() => {
		// ?PageSize=15
		request(`/api-key?Page=${filters?.page}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getApiKeyFilterParametersFunc(filterPrm))
				dispatch(getApiKey(res.data.result.items))
			}
		})
	}, [filters])

	useEffect(() => {
		request('/company/select-list?export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setCompanySelectList(res.data.result.items)
			}
		})
		request('/identity/role/select-list?export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setRolesSelectList(res.data.result.items)
			}
		})

	}, [])

	useEffect(() => {

		if (apiKeyAddingCompanyId?.value) {
			request(`/identity/user/select-list?Export=true&OnlyManager=true&CompanyId=${apiKeyAddingCompanyId.value}`, {
				method: 'GET',
			}).then((res) => {
				if (res.data.ok) {
					setUserSelectList(res.data.result.items)
				}
			})

			request(`/identity/user/select-list?Export=true&CompanyId=${apiKeyAddingCompanyId?.value}`, {
				method: 'GET',
			}).then((res) => {
				if (res.data.ok) {
					setSubstituteUserSelectList(res.data.result.items)
				}
			})
		}
	}, [apiKeyAddingCompanyId])

	let nodes = apiKey

	let data = { nodes }

	const handleDownloadXlsx = () => {
		request(`/api-key/export-excel?Page=${filters?.page}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				downloadFileFromStream('api-key.xlsx', res.data.result)
			}
		})
	}


	const apiAddBtn = () => {
		request(`/api-key`, {
			method: 'POST',
			data: {
				expirationDate: apiKeyAddingExpirationDate,
				allowedIpAddresses: apiKeyAddingAllowedIpAddresses,
				status: statusChecked,
				allowedCompanyIds: hierarchicalTreeList,
				companyId: apiKeyAddingCompanyId?.value,
				generateNewKey: false
			},
		}).then((res) => {
			if (res.data.ok) {
				//dispatch(addGiftType(res.data.result))
				request('/api-key', {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						let filterPrm = {
							pageIndex: res.data.result.pageIndex,
							pageSize: res.data.result.pageSize,
							totalCount: res.data.result.totalCount,
							totalPages: res.data.result.totalPages,
							indexFrom: res.data.result.indexFrom,
						}
						dispatch(getApiKeyFilterParametersFunc(filterPrm))
						dispatch(getApiKey(res.data.result.items))
					}
				})
			}
			toast.success('Ekleme işlemi başarılı')
			setTimeout(() => {
				dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
			}, 100)
			setTimeout(() => {
				dispatch(openModal({ open: true, type: { modalId: res.data.result, modalStatus: 'getApiKey' } }))
			}, 150)
			//alert(res.data.result)
		})
	}

	const goToPrevPage = () => {
		if (apiKeyFilterParameters?.pageIndex == 0) return
		setFilters(prev => ({...prev, page: apiKeyFilterParameters?.pageIndex - 1}))
	}
	const goToNextPage = () => {
		if (apiKeyFilterParameters?.pageIndex + 1 >= apiKeyFilterParameters?.totalPages) return
		setFilters(prev => ({...prev, page: apiKeyFilterParameters?.pageIndex + 2}))
	}

	const goToPage = (index) => {
		setFilters(prev => ({...prev, page: index + 1}))
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: apiKeyFilterParameters,
	}

	const sortTable = (e, column) => {
		e.preventDefault()
		e.stopPropagation()
		setFilters(prev => ({...prev, sortingColumnName: column.name, sortingOrderByVal: prev.sortingOrderByVal == "asc" ? 'desc' : 'asc'}))
	}

	const handleFilterChange = (filterName, value) => {
		setFilters({ ...filters, [filterName]: value })
	}


	useEffect(() => {
		setHierarchicalTreeList(treeChecked)
	}, [treeChecked])

	return (
		<div className='currency panel-table location-group incoming-gift'>
			<div className='topButtonGroup'>
				<div className='topButtonGroup-container'>
					{/* <Loading width="20px" height="20px" wholePage={false} borderWidth = "3px"/> */}
					<Button
						onclick={handleDownloadXlsx}
						name={t('exportAsExcel')}
						img={images.exit}
						color='#008814'
						backgroundColor='transparent'
						height='38px'
					/>
					{currentUser?.permissions[permissionTypes.ApiKeysUpsert] && (
						<Button
							onclick={() => dispatch(openModal({ open: true, type: { modalId: 3, modalStatus: 'apiKey' } }))}
							name={t('addApiKey')}
							img={images.plus}
							color='#fff'
							backgroundColor='#1e74ad'
							height='38px'
						/>
					)}
				</div>
			</div>

			<div className='tableCard'>
				<Table
					gridCSS='apiKey'
					deleteBtn={true}
					sortTable={sortTable}
					paginationAllFunctionsList={paginationAllFunctionsList}
					data={data}
					columns={COLUMNS}
					//filterType={5}
					handleFilterChange={handleFilterChange}
					setFilters={setFilters}
					isLoading={loading}
				/>
			</div>

			{/* .incoming-gift.panel-table .table */}
			{
				modalStatus == "getApiKey" && (
					<Modal>
                        <Title name={t('apiKey')} color='#242424' fontweight='bold' size='24px' />
						<div style={{marginTop: "16px", marginBottom: "16px", fontSize: "16px"}}>
							{modalId}
						</div>
						<Button onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))} name={t('close')} color='#fff' backgroundColor='#1392e5' height='48px' />
					</Modal>
				)
			}

			{modalId == 3 && modalToggle && modalStatus == 'apiKey' && (

				<Modal customModal={'userModal'}>
					<div className='tab-modal'>
						<div className='tab-modal-lgTitle'>
							<Title name={t('addApiKey')} color='#242424' fontweight='bold' size='24px' />
						</div>
						<div className='tab-modal-titles'>
							<div className={tabModal == 0 ? 'active' : ''} onClick={() => setTabModal(0)}>
							{t('apiKey')}
							</div>
							<div className={tabModal == 1 ? 'active' : ''} onClick={() => setTabModal(1)}>
							{t('companies')}
							</div>
						</div>
						{tabModal == 0 ? (
						<div className='modal-type1 modal-type1-changed'>

							{/* <div style={{ marginTop: '-10px' }} className='userManagement-addingModal'>
								<Input value={userManagementAddingName} setValue={setUserManagementAddingName} labelColor='#292929' label={t('name')} type='text' />
								<Input
									value={userManagementAddingSurname}
									setValue={setUserManagementAddingSurname}
									labelColor='#292929'
									label={t('surname')}
									type='text'
								/>
							</div>
							<div className='userManagement-addingModal'>
								<Input
									value={userManagementAddingUsername}
									setValue={setUserManagementAddingUsername}
									labelColor='#292929'
									label={t('username')}
									type='text'
								/>
								<Input value={userManagementAddingEmail} setValue={setUserManagementAddingEmail} labelColor='#292929' label={'Email'} type='text' />
							</div> */}

							{/* <div className='userManagement-addingModal'>
								<Input
									value={userManagementAddingPassword}
									setValue={setUserManagementAddingPassword}
									labelColor='#292929'
									label={t('password')}
									type='text'
								/>
								<Input
									value={userManagementAddingConfirmPassword}
									setValue={setUserManagementAddingConfirmPassword}
									labelColor='#292929'
									label={t('confirmPassword')}
									type='text'
								/>
							</div> */}
							<div className='addItemsPage-container-date' style={{width: "100%"}}>
								<label htmlFor=''>{t('expirationDate')}</label>
								<DatePicker
									placeholderText={t('select')}
									//showTimeSelect
									dateFormat='dd/MM/yyyy'
									selected={apiKeyAddingExpirationDate}
									onChange={(date) => setApiKeyAddingExpirationDate(date)}
									showPopperArrow={false}
									locale={Locale()}
									isClearable
								/>
							</div>

							<div className='userManagement-addingModal apiKey-addingModal' style={{alignItems: "stretch"}}>
								{/* <SelectDr
									ref={selectRef}
									// onChange={(value) => setLocationGroupEditStates((prev) => ({ ...prev, parentId: value ? value : '' }))}
									onChange={(value) => setApiKeyAddingRoles(value ? value : '')}
									//(value) => setLocationGroupAddingParentName(value ? value : '')
									//defaultValue={{ value: editableData?.parentId, label: editableData?.parentName }}
									type={1}
									isLoading={loading}
									options={rolesSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
									placeholder={''}
									label={t('allowedIpAddresses')}
								/> */}
								<div style={{height: "100%", width: "50%"}}>
								<label style={{fontSize: "15px"}}>{t('allowedIpAddresses')}</label>
								<TagsInput
									value={apiKeyAddingAllowedIpAddresses || []}
									onChange={(ips) => setApiKeyAddingAllowedIpAddresses(ips)}
									addKeys={[13, 9]}
									onlyUnique
									addOnBlur
								/>
							</div>
								<SelectDr
									ref={selectRef}
									// onChange={(value) => setLocationGroupEditStates((prev) => ({ ...prev, parentId: value ? value : '' }))}
									onChange={(value) => setApiKeyAddingCompanyId(value ? value : '')}
									//defaultValue={{ value: editableData?.parentId, label: editableData?.parentName }}
									type={0}
									isLoading={loading}
									options={companySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
									placeholder={''}
									label={t('companyName')}
								/>
							</div>
							{/* <div className='userManagement-addingModal'>
								<SelectDr
									ref={selectRef}
									// onChange={(value) => setLocationGroupEditStates((prev) => ({ ...prev, parentId: value ? value : '' }))}
									onChange={(value) => setUserManagementAddingManagerId(value ? value : '')}
									//defaultValue={{ value: editableData?.parentId, label: editableData?.parentName }}
									type={0}
									isLoading={loading}
									options={userSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
									placeholder={''}
									label={t('thismanager')}
								/>
								<SelectDr
									ref={selectRef}
									// onChange={(value) => setLocationGroupEditStates((prev) => ({ ...prev, parentId: value ? value : '' }))}
									onChange={(value) => setUserManagementAddingSubstitute(value ? value : '')}
									//(value) => setLocationGroupAddingParentName(value ? value : '')
									//defaultValue={{ value: editableData?.parentId, label: editableData?.parentName }}
									type={1}
									isLoading={loading}
									options={substituteUserSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
									placeholder={''}
									label={t('proxyUser2')}
								/>
							</div> */}

							<div className='modal-type1-checkArea'>
								<input onChange={(e) => setStatusChecked(e.target.checked ? 1 : 0)} type='checkbox' name='' id='' />
								<div>{t('active')}</div>
							</div>
							{/* <div style={{ marginTop: '-10px' }} className='modal-type1-checkArea'>
								<input onChange={(e) => setIsManagerChecked(e.target.checked)} type='checkbox' name='' id='' />
								<div>{t('manager')}</div>
							</div> */}

						</div>
						) : (
							<div className='tab-modal-tab2'>
								<CheckboxTree
									nodes={treeNodes}
									noCascade={true}
									checked={treeChecked}
									expanded={treeExpanded}
									onCheck={(checked, targetNode) => {
										checked = checked.map(v => parseInt(v))
										if(targetNode?.checked && targetNode.isParent) {
											targetNode.children?.forEach(child => {
												if(!checked.includes(child.value)) {
													checked.push(child.value)
												}
											});

											if(!treeExpanded?.includes(targetNode.value)) {
												setTreeExpanded(prev => [...prev, targetNode.value])
											}
										}

										setTreeChecked(checked)
									}}
									onExpand={(expanded) => {
										expanded = expanded.map(v => parseInt(v))
										setTreeExpanded(expanded)
									}}
									//iconsClass="fa5"
									icons={{
										check: <MdOutlineCheckBox size={24} />,
										uncheck: <MdOutlineCheckBoxOutlineBlank size={24} />,
										leaf: <RiCheckboxMultipleFill />,
										expandClose: <FaChevronRight />,
										expandOpen: <FaChevronDown />,
									}}
									//expandIconRenderer={renderExpandIcon}
								/>
							</div>
						)}
						<div className='tab-modal-modalGroup'>
							<Button onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))} name={t('cancel')} color='#fff' backgroundColor='#c1312f' height='48px' />
							<Button onclick={apiAddBtn} name={t('add')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default ApiKey
