import images from 'assets/images/images';
import Accordion from 'components/common/Accordion'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openAccordion } from 'redux/features/general/generalSlice';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'hooks/useRequest';
import { toast } from 'react-toastify';

const SidebarBottom = () => {
    const dispatch = useDispatch();
    const {accordions} = useSelector(state => state.general)
    const { permissions } = useSelector((state) => state.configuration)
	const { currentUser } = permissions.configurations
    const { politikalar, teknikdestek} = accordions
    const { t } = useTranslation();
    const [request] = useRequest();

    console.log(currentUser, "currentUser");
    const accordionList = [
        
        // {
        //     id: 'etikhat',
        //     name: "Etik Hat",
        //     open: etikhat,
        //     type: 0,
        //     content: [
        //         {
        //             name: "Etik İlkeler",
        //             url: "/"
        //         },
        //         {
        //             name: "Hediye ve Ağırlama Politikası",
        //             url: "/"
        //         },
        //         {
        //             name: "Diğer Politikalar",
        //             url: "/"
        //         },
        //     ]
        // },
        {
            id: 'politikalar',
            name: t('policies'),
            open: politikalar,
            type: 0,
            content: [
                {
                    name: t('ethicalPrinciples'),
                    url: localStorage.getItem('language') == "tr" ? "https://cdn.koc.com.tr/cmscontainer/kocholding/media/koc/01hakkinda/uyum/2024/01-koc_toplulugu_etik_ilkeleri_tr-050324_1.pdf" : "https://cdn.koc.com.tr/cmscontainer/kocholding/media/koc/01hakkinda/uyum/2024/01-koc_toplulugu_etik_ilkeleri_eng-040324.pdf"
                },
                {
                    name: t('giftandHospitalityPolicy'),
                    url: localStorage.getItem('language') == "tr" ? "https://cdn.koc.com.tr/cmscontainer/kocholding/media/koc/01hakkinda/uyum/2024/8hediye-ve-agirlama-politikasi_tr-040324.pdf" : "https://cdn.koc.com.tr/cmscontainer/kocholding/media/koc/01hakkinda/uyum/2024/8gift-and-entertainment-policy_eng-040324.pdf"
                },
                {
                    name: t('otherPolicies'),
                    url: localStorage.getItem('language') == "tr" ? "https://www.koc.com.tr/hakkinda/etik-ilkeler-ve-uyum-politikalari" : "https://www.koc.com.tr/about-us/code-of-ethics-and-compliance-policies"
                },
            ]
        },
        {
            id: 'teknikdestek',
            name: t('technicalSupport'),
            open: teknikdestek,
            type: 1,
            content: [
                {
                    img: images.phone,
                    name: "0850 399 22 33",
                    url: "",
                    noClick: true
                },
                {
                    img: images.wp,
                    name: "Whatsapp",
                    url: "https://api.whatsapp.com/send/?phone=908503992233&text&type=phone_number&app_absent=0"
                },
                {
                    img: images.mail,
                    name: "cozum@kocweb.com",
                    url: "mailto:cozum@kocweb.com"
                },
                {
                    img: images.comment,
                    name: "Web Chat",
                    url: "/",
                    clickFunc: true
                },
            ]


        },
    ]

    const fetchAndOpenPDF = async () => {
        let apiUrl = '';
    
        const permissions = currentUser?.permissions;
    
        if (permissions) {
          if (permissions['Gifts.ViewAllCompanies']) {
            apiUrl = '/file/document/user-manual-admin.pdf';
          } else if (permissions['Gifts.ViewOwnCompany']) {
            apiUrl = '/file/document/user-manual-compliencemanager.pdf';
          } else if (permissions['Gifts.ManagerApprove']) {
            apiUrl = '/file/document/user-manual-manager.pdf';
          } else {
            apiUrl = '/file/document/user-manual-user.pdf';
          }
        } else {
          return;
        }

        request(apiUrl, {
            method: 'GET',
            responseType: 'blob'
        }, true).then((res) => {
            if(res?.status !== 200){
              toast.error("Dosya Bulunamadı");
              return;
            }

            const blob = new Blob([res.data], { type: 'application/pdf' });
      
            const blobUrl = window.URL.createObjectURL(blob);

            const newWindow = window.open(blobUrl, '_blank');
            if (newWindow) {
              newWindow.focus();
            } else {
              toast.error("Yeni sekme açılması engellendi. Lütfen tarayıcı ayarlarınızı kontrol edin.");
            }
        })
      };

    return (
    <div className='sidebar-bottom cabin-bold'>
         <div className='sidebar-bottom-link'>
             <button onClick={fetchAndOpenPDF}>{t('userManual')}</button>
           </div>
           <div className='sidebar-bottom-link'>
             <a rel="noreferrer" target="_blank" href={localStorage.getItem('language') == "tr" ? "https://secure.ethicspoint.eu/domain/media/tr/gui/108227/" : "https://secure.ethicspoint.eu/domain/media/en/gui/108227/index.html"}>{t('ourEthics')}</a>
           </div>
        {
            accordionList.map((accordion, i) => (
              <Accordion key={i} onclick={() => dispatch(openAccordion(accordion.id))} title={accordion.name} open={accordion.open} content={accordion.content} type={accordion.type}/>
            ))
        }
    </div>
  )
}

export default SidebarBottom